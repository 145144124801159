import React, { Fragment, useState } from "react";
import Chip from "@mui/material/Chip";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate, useParams } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Alert, Box, Button, Divider, Grid, Typography } from "@mui/material";

import DataGrid from "@components/datagrid";
import TableLoader from "@components/TableLoader";
import { documentListColumns } from "../utils/columnDocumentList";

import { validationListColumns } from "../utils/columnValidationList";
import KycChip from "./KycChip";

export default function KycDetail({
  selectedKyc,
  isLoading,
  isHistory = false,
  onDocModalClose,
  isModalDetails,
  setIsModalDetails,
}) {
  const { appID } = useParams();

  const colomnProps = {
    setIsModalDetails,
  };

  const navigate = useNavigate();
  const businessTypes = {
    ASSOCIATION: "Association",
    COMPANY: "Company",
    INDIVIDUAL_OR_SELF_EMPLOYED: "Individual or self employed",
  };

  const currentDocumentColumns = isHistory
    ? documentListColumns({ ...colomnProps }).filter(
        (column) => column.id !== "created_at"
      )
    : documentListColumns({ ...colomnProps });

  const kycInfos = [
    {
      label: "# ID",
      value: selectedKyc?.id,
    },
    {
      label: "Business name",
      value: selectedKyc?.business_name,
    },
    // {
    //   label: 'Status',
    //   value: <KycChip status={selectedKyc?.status} />,
    // },
    {
      label: "Business type",
      value: businessTypes[selectedKyc?.business_type],
    },
    {
      label: "Business country",
      value: selectedKyc?.business_country,
    },
    {
      label: "Business city",
      value: selectedKyc?.business_city,
    },
    {
      label: "Business website",
      value: selectedKyc?.business_website,
    },
    {
      label: "Business address",
      value: selectedKyc?.business_address,
    },
    {
      label: "Business email",
      value: selectedKyc?.business_customer_support_email,
    },
    {
      label: "Business phone",
      value: selectedKyc?.business_customer_support_phone,
    },
    {
      label: "Business promoter name",
      value: selectedKyc?.business_promoter_name,
    },
    {
      label: "Business promoter email",
      value: selectedKyc?.business_promoter_email,
    },
    {
      label: "Business promoter phone",
      value: selectedKyc?.business_promoter_phone,
    },
    {
      label: "Business customer support name",
      value: selectedKyc?.business_customer_support_name,
    },
    {
      label: "Business customer support email",
      value: selectedKyc?.business_customer_support_email,
    },
    {
      label: "Business customer support phone",
      value: selectedKyc?.business_customer_support_phone,
    },
  ];

  return (
    <Fragment>
      {selectedKyc?.history?.length > 0 &&
        selectedKyc?.history[selectedKyc?.history?.length - 1]?.status !==
          "VERIFIED" && (
          <Box sx={{ textAlign: "right" }}>
            <Chip
              onClick={() =>
                navigate(`/${appID}/kycsHistory/${selectedKyc?.id}`)
              }
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <InfoOutlinedIcon
                    style={{ marginRight: 5 }}
                    fontSize="small"
                    color="warning"
                  />{" "}
                  Pending modifications
                </Box>
              }
              color="secondary"
              variant="outlined"
              sx={{ cursor: "pointer" }}
            />
          </Box>
        )}

      {isHistory && (
        <Box sx={{ textAlign: "right" }}>
          <Button
            color="primary"
            variant="outlined"
            sx={{ boxShadow: "none" }}
            component={Link}
            to={`/${appID}/kycs/manage`}
            startIcon={selectedKyc?.id ? <EditIcon /> : <AddIcon />}
          >
            Update modifications
          </Button>
        </Box>
      )}

      <Fragment>
        <Box mb={3} width={100 / 3 + "%"} fontWeight="bold">
          <Alert w="fit-content" severity="info">
            <Typography fontWeight="bold">
              KYC Status per country is under validation list
            </Typography>
          </Alert>
        </Box>
        <Grid container rowSpacing={3} columnSpacing={2}>
          {kycInfos.map((info, key) => (
            <Grid key={key} item xs={12} md={6} lg={4}>
              <Typography variant="body2" fontSize={13} color="text.secondary">
                {info.label}
              </Typography>
              <Box>{info.value ?? "N/A"}</Box>
            </Grid>
          ))}
        </Grid>
      </Fragment>

      <Box sx={{ mt: 5 }}>Documents list</Box>
      <Divider sx={{ my: 1 }} />

      {selectedKyc?.documents &&
        (isLoading ? (
          <TableLoader rows={2} columns={currentDocumentColumns} />
        ) : (
          <DataGrid
            data={selectedKyc?.documents}
            hidePadding
            showPagination={false}
            showSearch={false}
            column={currentDocumentColumns}
          />
        ))}
      {selectedKyc?.validations?.length > 0 && (
        <>
          <Box sx={{ mt: 3 }}>Validations list</Box>
          <Divider sx={{ my: 1 }} />

          {isLoading ? (
            <TableLoader rows={2} columns={validationListColumns} />
          ) : (
            <DataGrid
              data={selectedKyc?.validations}
              searchTitle="Search by country"
              query="country"
              dataType="validations"
              hidePadding
              showPagination={false}
              showSearch={false}
              column={validationListColumns}
            />
          )}
        </>
      )}
    </Fragment>
  );
}
